import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { Token } from '@pancakeswap/sdk'


const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && token.chainId) {
      return `https://static.musk-cdn.com/static/${token.chainId}/${getAddress(
        token.address,
      ).toLowerCase()}.png`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export default getTokenLogoURL
